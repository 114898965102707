<template>
  <div class="main">
    <div class="content">
      <div class="tabs">
        <p class="tab" :class="{'active':  activeIndex === '1'}" @click="getList('1')">新闻资讯</p>
        <p class="tab" :class="{'active':  activeIndex === '2'}" @click="getList('2')">通知公告</p>
      </div>
      <div class="news">
        <div class="news-li" v-for="(item, index) in newsList" :key="index">
          <div class="news-img">
            <img :src="item.image" alt="">
          </div>
          <div class="news-list">
            <ul class="news-list-ul">
              <li class="news-list-li">
                <p class="news-title">{{ item.name }}</p>
                <p class="news-desc">{{ item.intro }}</p>
                <div class="news-time">
                  <span>{{ item.publish }}</span>
                  <span class="news-more" @click="toDetailPage()">查看详情</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <pagination
        :num="num"
        :limit="limit"
        @getNew="getNew"
        v-if="num"
      ></pagination>
    </div>
  </div>
</template>
<script>
import pagination from "@/components/pc/pagination.vue";
export default {
  name: 'advicePage',
  components: {
    pagination,
  },
  data () {
    return {
      activeIndex: '1',
      newsList: [],
      num: 10, //数据总个数
      limit: 10, //一页显示几个
      nowPage: 1, //当前页码
    }
  },
  created () {
    this.ID = this.$route.query.ID
    this.getList('1')
  },
  methods: {
    toDetailPage () {
      this.$router.push({ name: 'newsDetailPage', query: { id: this.ID } })
    },
    getList(index) {
      this.activeIndex = index;
      this.newsList = [];
      this.$axios.post(`/v1/qn/newsList`, { area_id: this.ID, type: this.activeIndex }).then((res) => {
          if (res.code == 0) {
            this.newsList = res.data.data;
          }
        });
    },
    // 翻页
    getNew(nowPage) {
      this.nowPage = nowPage;
      this.getList();
    },
  }
}
</script>
<style lang="scss" scoped>
.main {
  background-color: #F7FAFF;
  padding-top: 60px;
  .content {
    width: 1200px;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 120px;
    background-color: #FFFFFF;
    .tabs {
      display: flex;
      align-items: center;
      justify-content: center;
      .tab {
        font-weight: 500;
        font-size: 24px;
        color: #666666;
        line-height: 33px;
        margin: 0 62px;
      }
      .active {
        position: relative;
        color: #4E6EF2;
        &::after {
          position: absolute;
          content: '';
          width: 20%;
          height: 2px;
          background: #4E6EF2;
          border-radius: 1px;
          bottom: -10px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    .news {
      box-sizing: border-box;
      width: 1140px;
      margin: 0 auto;
      margin-top: 50px;
      margin-bottom: 60px;
      .news-li {
        border-bottom: 1px solid #E0E0E0;
        display: flex;
        padding: 40px 0 50px 0;
        box-sizing: border-box;
      }
      .news-img {
        width: 306px;
        height: 172px;
        border-radius: 10px 10px 10px 10px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px 10px 10px 10px;
        }
      }
      .news-list {
        margin-left: 15px;
        width: 774px;
        .news-list-ul {
          .news-list-li {
            padding: 18px 35px 20px 19px;
            .news-title {
              font-weight: bold;
              font-size: 18px;
              color: #333333;
              line-height: 25px;
              text-align: left;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
            }
            .news-desc {
              font-weight: 500;
              font-size: 18px;
              color: #666666;
              line-height: 28px;
              text-align: left;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              margin-top: 15px;
            }
            .news-time {
              font-weight: 500;
              font-size: 13px;
              color: #666666;
              line-height: 18px;
              text-align: left;
              margin-top: 12px;
              display: flex;
              justify-content: space-between;
              .news-more {
                position: relative;
                &::after {
                  content: "";
                  position: absolute;
                  width: 19px;
                  height: 10px;
                  background-image: url('~@/assets/more-1.png');
                  background-size: 100% 100%;
                  top: 50%;
                  right: -26px;
                  transform: translateY(-50%);
                }
              }
            }
            &:hover {
              .news-title {
                color: #366CEC;
              }
              .news-more {
                color: #366CEC;
                &::after {
                  background-image: url('~@/assets/more.png');
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>